import { AvatarSize } from 'components/Avatar/avatar.types';
import { IconSize } from 'components/Icon/icon.types';

export const getNextIconSize = (size: IconSize | AvatarSize, larger = true, count = 1): IconSize => {
  let nextSize: IconSize;
  switch (size) {
    case 'xs':
      nextSize = larger ? 'sm' : 'xs';
      break;
    case 'sm':
      nextSize = larger ? 'md' : 'xs';
      break;
    case 'md':
      nextSize = larger ? 'lg' : 'sm';
      break;
    case 'lg':
      nextSize = larger ? 'xl' : 'md';
      break;
    case 'xl':
      nextSize = larger ? '2xl' : 'lg';
      break;
    case '2xl':
      nextSize = larger ? '2xl' : 'xl';
      break;
    default:
      nextSize = size as IconSize;
      break;
  }

  if (count > 1) {
    nextSize = getNextIconSize(nextSize, larger, count - 1);
  }

  return nextSize;
};
