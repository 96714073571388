import React, { useRef, useMemo } from 'react';
import { useDay } from '@datepicker-react/hooks';
import { today, useDatePickerContext } from '../../../context/datepicker.context';
import { Button } from '../../../../Button/button.component';
import { DayVariant, dayStyles } from './day.config';
import format from 'date-fns/format';
import { Box } from '@chakra-ui/react';

interface DayProps {
  dayLabel: string;
  date: Date;
  onDaySelected: () => void;
}

export const Day = ({ dayLabel, date, onDaySelected }: DayProps): JSX.Element => {
  const dayRef = useRef(null);
  const { startDate, endDate, dateFormat } = useDatePickerContext();
  const formattedDates = useMemo(
    () => ({
      currentDay: format(date, dateFormat),
      startDate: startDate && format(startDate, dateFormat),
      endDate: endDate && format(endDate, dateFormat),
    }),
    [date, dateFormat, endDate, startDate],
  );
  const isRangeStart = formattedDates.currentDay === formattedDates.startDate;
  const isRangeEnd = formattedDates.currentDay === formattedDates.endDate;
  const isToday = formattedDates.currentDay === format(today, dateFormat);

  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useDatePickerContext();

  const { disabledDate, isSelected, onClick, onKeyDown, onMouseEnter, tabIndex } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  const buttonVariant = useMemo((): DayVariant => {
    switch (true) {
      case disabledDate:
        return 'disabled';
      case isRangeStart:
        return 'selectedStart';
      case isRangeEnd:
        return 'selectedEnd';
      case isSelected && !isRangeStart && !isRangeEnd:
        return 'inRange';
      default:
        return 'default';
    }
  }, [disabledDate, isRangeEnd, isRangeStart, isSelected]);

  if (!dayLabel) {
    return <></>;
  }

  const onSelectDay = (): void => {
    onClick();
    onDaySelected();
  };

  return (
    <Button
      onClick={onSelectDay}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      type="button"
      ref={dayRef}
      size="sm"
      fontWeight="normal"
      isDisabled={disabledDate}
      overflow="hidden"
      pos="relative"
      {...dayStyles[buttonVariant]}
    >
      {isToday && (
        <Box
          pos="absolute"
          w="6"
          h="6"
          top="-4"
          right="-4"
          transform="rotate(45deg)"
          bg={isRangeStart ? 'background.default' : 'background.primary'}
        />
      )}
      {dayLabel}
    </Button>
  );
};
