import React, { useState, useMemo, MouseEventHandler } from 'react';
import { Placement, Avatar as CAvatar, AvatarProps as CAvatarProps } from '@chakra-ui/react';
import { Icon } from '../Icon/icon.component';
import { Tooltip } from '../Tooltip/tooltip.component';
import { AvatarSize, AvatarType, AvatarVariant } from './avatar.types';
import { getNextIconSize } from 'shared/utils/sizes';
import { AvatarBadge, AvatarBadgeProps } from './components/avatarBadge.component';
import { IconName } from '../../icons';
import AvatarRemoveButton from './components/avatarRemoveButton.component';
import AvatarImage from './components/avatarImage.component';

export interface KAvatarProps {
  badgeProps?: AvatarBadgeProps;
  handleClick?: MouseEventHandler<HTMLSpanElement>;
  handleRemoveButtonClick?: MouseEventHandler<HTMLDivElement>;
  hideBorder?: boolean;
  iconName?: IconName;
  name?: string;
  randomizeVariant?: boolean;
  randomizationType?: AvatarType;
  showBadge?: boolean;
  showRemoveButton?: boolean;
  showTooltip?: boolean;
  size?: AvatarSize;
  src?: string;
  tooltipPlacement?: Placement;
  tooltipText?: string;
  variant?: AvatarVariant;
  webpSrc?: string;
}

export type AvatarProps = KAvatarProps & CAvatarProps;

const getRandomizedAvatarVariant = (charCode: number, type: AvatarType): AvatarVariant => {
  if (charCode >= 65 && charCode <= 77) {
    return type === 'outline' ? 'outlineAccent' : 'accent';
  } else {
    return type === 'outline' ? 'outlineAccentSubtle' : 'accentSubtle';
  }
};

export const Avatar = ({
  badgeProps,
  handleClick,
  handleRemoveButtonClick,
  hideBorder = false,
  iconName,
  name = '',
  randomizeVariant = false,
  randomizationType = 'outline',
  showBadge = false,
  showRemoveButton = false,
  showTooltip = true,
  size = 'md',
  src,
  tooltipPlacement = 'bottom',
  tooltipText,
  variant = 'default',
  webpSrc,
  ...rest
}: AvatarProps): JSX.Element => {
  const [isHoveringAvatar, setIsHoveringAvatar] = useState(false);
  const [isHoveringRemoveButton, setIsHoveringRemoveButton] = useState(false);
  const shouldHideRemoveButton = size === 'xs';

  let _variant = variant;

  if (randomizeVariant) {
    _variant = getRandomizedAvatarVariant(name.charCodeAt(0), randomizationType);
  }

  // These styles are not defined in the config file because they
  // should only be applied if the avatar has a click handler defined
  const hoverActiveStyles = useMemo(() => {
    let styles = {
      _hover: {
        img: { opacity: 1 },
        _after: {
          content: '""',
          backgroundColor: 'background.alpha.hover',
          w: '110%',
          h: '110%',
          borderRadius: 'full',
          position: 'absolute',
        },
      },
      _active: {
        img: { opacity: 1 },
        _after: {
          content: '""',
          backgroundColor: 'background.alpha.pressed',
          w: '110%',
          h: '110%',
          borderRadius: 'full',
          position: 'absolute',
        },
      },
    };

    if (variant === 'contrast') {
      styles._hover._after.backgroundColor = 'background.alphaContrast.hover';
      styles._active._after.backgroundColor = 'background.alphaContrast.pressed';
    }

    return styles;
  }, [variant]);

  const avatarMarkup = (
    <CAvatar
      name={iconName ? '' : name}
      size={size}
      src={!!webpSrc ? undefined : src}
      variant={_variant}
      boxShadow="0 0 0 1px var(--chakra-colors-border-strongContrast)"
      borderWidth={hideBorder ? '0px' : '2px'}
      cursor="default"
      {...(iconName && {
        icon: <Icon name={iconName} size={getNextIconSize(size, true, size === 'sm' || size === 'xs' ? 2 : 1)} />,
      })}
      {...(handleClick && {
        onClick: handleClick,
        cursor: 'pointer',
        ...hoverActiveStyles,
      })}
      onMouseEnter={(): void => setIsHoveringAvatar(true)}
      onMouseLeave={(): void => setIsHoveringAvatar(false)}
      {...rest}
    >
      {showRemoveButton && isHoveringAvatar && !shouldHideRemoveButton && (
        <AvatarRemoveButton
          handleRemoveButtonClick={handleRemoveButtonClick}
          isBeingHovered={isHoveringRemoveButton}
          setIsBeingHovered={setIsHoveringRemoveButton}
        />
      )}
      {showBadge && <AvatarBadge avatarSize={size} {...badgeProps} />}
      {webpSrc && src && <AvatarImage src={src} webpSrc={webpSrc} />}
    </CAvatar>
  );

  if (showTooltip && !isHoveringRemoveButton) {
    return (
      <Tooltip label={tooltipText || name} placement={tooltipPlacement}>
        {avatarMarkup}
      </Tooltip>
    );
  }

  return avatarMarkup;
};
