import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Box, PlacementWithLogical } from '@chakra-ui/react';
import { Tooltip } from 'components/Tooltip';

export interface WithTruncationProps {
  isTruncated?: boolean;
  noOfLines?: number;
  tooltipPlacement?: PlacementWithLogical;
  children?: ReactNode | string;
}

export function withTruncation<P extends WithTruncationProps>(Component: React.ComponentType<P>): React.FC<P> {
  function WithTruncation({
    isTruncated = false,
    noOfLines = 1,
    tooltipPlacement,
    children,
    ...rest
  }: WithTruncationProps): JSX.Element {
    const [isTextOverFlowing, setIsTextOverFlowing] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const onCheckTextOverFlowing = useCallback((containerRefValue) => {
      if (containerRefValue && containerRefValue.current) {
        const textScrollHeight = containerRefValue.current?.children[0].scrollHeight || 0;
        const containerOffsetHeight = containerRefValue.current?.offsetHeight || 0;
        return textScrollHeight > containerOffsetHeight;
      }
      return false;
    }, []);

    useEffect(() => {
      const checkValue = onCheckTextOverFlowing(containerRef);
      setIsTextOverFlowing(checkValue);
    }, [containerRef, onCheckTextOverFlowing]);

    if (isTruncated) {
      return (
        <Tooltip
          className="TruncatedText__Tooltip"
          label={children?.toLocaleString() || ''}
          isDisabled={!isTextOverFlowing}
          placement={tooltipPlacement}
        >
          <Box ref={containerRef} display="inline-block" position="relative" className="TruncatedText__Wrapper">
            <Component
              className="TruncatedText__TextContent"
              cursor={!isTextOverFlowing ? '' : 'pointer'}
              noOfLines={noOfLines}
              children={children}
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
              }}
              {...(rest as P)}
            />
          </Box>
        </Tooltip>
      );
    }

    return <Component children={children} {...(rest as P)} />;
  }
  WithTruncation.displayName = Component.displayName;

  return WithTruncation;
}
